import { Pay24, Сurrencies } from "../../api";

let initialState = {
  СurrenciesList: [],
  СurrenciesLoading: false,
  СurrenciesListFiat: [],
  sale: 0,
  withdrawl: "",
};

const СurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_СURRENCIES_PAGE": {
      return {
        ...state,
        СurrenciesList: Object.values(
          typeof action?.data?.currencies === "object" &&
            action?.data?.currencies
        )
          .sort((a, b) => b.balance - a.balance)
          .filter(
            (item) =>
              item.currency !== "KOKO" &&
              item.currency !== "BNB" &&
              item.currency !== "COCOS" &&
              item.currency !== "DGB" &&
              item.currency !== "ONE" &&
              item.currency !== "HOT" &&
              item.currency !== "SC" &&
              item.currency !== "XVG" &&
              item.currency !== "WAVES"
          ),
        СurrenciesLoading: true,
      };
    }
    case "SET_СURRENCIES_FIAT": {
      return {
        ...state,
        СurrenciesListFiat: action.data,
      };
    }

    case "SET_СURRENCIES_SALES": {
      return {
        ...state,
        sale: action.data?.currencies?.KGS?.rate,
      };
    }

    case "SET_СURRENCY_W": {
      return {
        ...state,
        withdrawl: action.data.currency,
      };
    }

    default:
      return state;
  }
};
export const setСurrenciesPage = (data) => ({
  type: "SET_СURRENCIES_PAGE",
  data,
});
export const setСurrenciesFiat = (data) => ({
  type: "SET_СURRENCIES_FIAT",
  data,
});

export const setСurrencySales = (data) => ({
  type: "SET_СURRENCIES_SALES",
  data,
});

export const setСurrencyW = (data) => ({
  type: "SET_СURRENCY_W",
  data,
});

export const getСurrenciesList = () => {
  return (dispath) => {
    Сurrencies
      .getСurrencies()
      .then((response) => dispath(setСurrenciesPage(response)));
  };
};

export const getСurrenciesListFiat = () => {
  return (dispath) => {
    Сurrencies
      .getСurrenciesFiat()
      .then((response) => dispath(setСurrenciesFiat(response)));
  };
};

export const getСurrencySales = () => (dispacth) => {
  Сurrencies
    .getСurrencySales()
    .then((response) => dispacth(setСurrencySales(response)));
};

export const getCurrencyW = (id) => {
  return (dispath) => {
    Pay24.getByIdServiceDetail(id).then((response) =>
      dispath(setСurrencyW(response))
    );
  };
};

export default СurrenciesReducer;
